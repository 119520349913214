
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function aggressiveFitmentsDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'close',
        'data-cm-role': 'close'
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-title' }, '\n      The aggressive fitment filter will open parameters for diameter, width and offset, allowing you to shop\n      wheels that are considered to be too big or wide, with offsets too low, or a combination of all three.\n    '), _createElement('p', {}, 'Wheelwiz will guarantee bolt pattern/center bore compatibility along with brake clearance.'), _createElement('p', {}, 'Wheelwiz will NOT be responsible for fender clearance or wheel rubbing.'), _createElement('h3', {}, 'Aggressive fitting wheels:'), _createElement('ul', {}, _createElement('li', {}, _createElement('p', {}, 'May require suspension modifications (lift kit)')), _createElement('li', {}, _createElement('p', {}, 'May require body modifications (plastic trimming/fender rolling)')), _createElement('li', {}, _createElement('p', {}, 'May cause abnormal tire wear')), _createElement('li', {}, _createElement('p', {}, 'May cause mechanical failure which can lead to an accident')), _createElement('li', {}, _createElement('p', {}, 'May cause damage to the bodywork, suspension, bearings, steering, and/or tires')), _createElement('li', {}, _createElement('p', {}, 'May be illegal for road use in some jurisdictions - please check your local regulations'))), _createElement('div', { 'className': 'label-warning' }, '\n      All aggressive wheels are considered final sale. Returns or exchanges due to fitment issues will not be\n      accepted.\n    '), _createElement('div', { 'className': 'button-container' }, _createElement('button', {
        'className': 'cm_submit cm_button cm_button__primary',
        'data-cm-role': 'close'
    }, 'I understand'), _createElement('button', {
        'onClick': window.Convermax.closeAggressiveFitmentsDialog,
        'className': 'cm_button cm_button__secondary'
    }, '\n        Decline\n      '))));
}
        export const componentNames = []