
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function yearsTilesContainerRT () {
    function repeatHierarchicalGroups1(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                return _createElement('div', { 'className': 'cm_vehicle-categories_category-wrapper' }, _createElement('a', {
                    'className': 'cm_vehicle-categories_category-container cm_vehicle-categories_link',
                    'href': this.href
                }, _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container cm_vehicle-categories_link' }, _createElement('h4', { 'className': 'cm_vehicle-categories_category-title' }, this.value), _createElement('span', {}, this.payload), _createElement('span', {}, 'Wheels And Tires'))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-categories' + (!this.isMultiLevel ? ' cm_vehicle-categories__flat ' : ' ') }, _createElement('h4', {}, 'Select Year'), _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-categories_category-wrapper-repeater cm_years-panel cmRepeater_hierarchicalGroups' },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups1.bind(this))
    ]));
}
        export const componentNames = []