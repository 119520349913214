//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-384:_1424,_3384,_4760,_6032,_4336,_132,_3808,_7282;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-384')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-384', "_1424,_3384,_4760,_6032,_4336,_132,_3808,_7282");
        }
      }catch (ex) {
        console.error(ex);
      }