import { useRef } from 'react';
import { useSelector } from 'react-redux';

import Table from 'Components/smallComponents/table.tsx';
import { useCollapsible } from 'Core/hooks/index.js';
import { searchResponseExtraSelector } from 'Core/selectors/search.js';
import { cloneSafe } from 'Utils/components.ts';

import type { FC } from 'react';
import type { TemplateFunction, TemplateFunctionInvoker, TemplateResult } from 'Components/types.ts';

enum VehiclePosition {
  Both = 'Both',
  Front = 'Front',
  Rear = 'Rear',
}

enum SizeType {
  OE = 'OE',
  Plus = 'Plus',
  Minus = 'Minus',
}

interface WheelOption {
  WheelSize: string;
  TireSizes: string[];
  OffsetMin: number;
  OffsetMax: number;
  RimDiameter: number;
  OESizeDelta: number;
  Type: SizeType;
  Position: VehiclePosition;
}

interface VehicleInfoExtra {
  BoltPattern: string;
  BoltPatternRear: string;
  Hubbore: string;
  HubboreRear: string;
  NutBoltHex: string;
  NutBoltThreadType: string;
  NutOrBolt: string;
  SeatType: string;
  TPMS: string;
  options: WheelOption[];
}

interface Params {
  sizesTable: TemplateFunctionInvoker<unknown>;
  characteristicsTable: TemplateFunctionInvoker<unknown>;
  collapsed: boolean;
  toggleCollapsed: () => void;
}

interface Props {
  template: TemplateFunction<Params>;
}

const sizeFields = ['Position', 'Size', 'Bolt Pattern', 'Offset', 'Hub', 'Tire'];
const charsFields = [/* 'L/S', */ 'Hardware', 'Thread', 'Hex', 'Seat Type', 'TPMS'];

const VehicleInfo: FC<Props> = ({ template }) => {
  const rootRef = useRef<HTMLElement>(null);
  const [collapsed, toggleCollapsed] = useCollapsible(rootRef, null, true);

  const vehicleInfo = useSelector(searchResponseExtraSelector)?.vehicle as VehicleInfoExtra;

  if (!vehicleInfo) {
    return null;
  }

  const sizes = vehicleInfo.options.map((o) => {
    return {
      'Position': o.Position === 'Both' ? 'F,R' : o.Position?.substring(0, 1),
      'Size': o.WheelSize && (o.Type === 'OE' ? `${o.WheelSize} (OEM)` : o.WheelSize),
      'Bolt Pattern':
        o.Position === VehiclePosition.Rear ? vehicleInfo.BoltPatternRear : vehicleInfo.BoltPattern,
      'Offset': o.OffsetMin === o.OffsetMax ? o.OffsetMin?.toString() : `${o.OffsetMin} to ${o.OffsetMax}`,
      'Hub': o.Position === VehiclePosition.Rear ? vehicleInfo.HubboreRear : vehicleInfo.Hubbore,
      'Tire': o.TireSizes.join('\n'),
    };
  }) as Record<string, string>[];

  const characteristics = [
    {
      // 'L/S': vehicleInfo.LS,
      'Hardware': vehicleInfo.NutOrBolt,
      'Thread': vehicleInfo.NutBoltThreadType,
      'Hex': vehicleInfo.NutBoltHex,
      'Seat Type': vehicleInfo.SeatType,
      'TPMS': vehicleInfo.TPMS,
    },
  ] as Record<string, string>[];

  const sizesTable = (templ: TemplateFunction<unknown>) => {
    const props = { template: templ, fields: sizeFields, data: sizes, columnBreakpoint: 580, key: 'sizes' };
    return (<Table {...props} />) as TemplateResult;
  };

  const characteristicsTable = (templ: TemplateFunction<unknown>) => {
    const props = {
      template: templ,
      fields: charsFields,
      data: characteristics,
      columnBreakpoint: 580,
      key: 'characteristics',
    };
    return (<Table {...props} />) as TemplateResult;
  };

  const component = template.call({ sizesTable, characteristicsTable, collapsed, toggleCollapsed });

  return cloneSafe(component, rootRef);
};

export default VehicleInfo;
